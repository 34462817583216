/**
 * App
*/

import $ from "jquery";
import AOS from "../../node_modules/aos/dist/aos";
import navbar from "Components/navbar";
import 'Components/micromodal.js';


document.addEventListener('DOMContentLoaded', function () {
  window.$ = $;

  AOS.init({
    offset: -210,
  });

  navbar();

  $('a[href^="#"]').on('click', function (e) {
    e.preventDefault();
    var target = this.hash;
    var header = $('.header').height();
    var $target = $(target);
    $('html, body').stop().animate({
      'scrollTop': ($target.offset().top - header)
    }, 900, 'swing', function () {
      window.location.hash = target;
    });
  });

  (function () {

    var doc = document.documentElement;
    var w = window;

    var prevScroll = w.scrollY || doc.scrollTop;
    var curScroll;
    var direction = 0;
    var prevDirection = 0;

    var header = document.getElementById('header');

    var checkScroll = function () {

      /*
      ** Find the direction of scroll
      ** 0 - initial, 1 - up, 2 - down
      */

      curScroll = w.scrollY || doc.scrollTop;
      if (curScroll > prevScroll) {
        //scrolled up
        direction = 2;
      }
      else if (curScroll < prevScroll) {
        //scrolled down
        direction = 1;
      }

      if (direction !== prevDirection) {
        toggleHeader(direction, curScroll);
      }

      prevScroll = curScroll;
    };

    var toggleHeader = function (direction, curScroll) {
      if (direction === 2 && curScroll > 52) {

        //replace 52 with the height of your header in px

        header.classList.add('hide');
        prevDirection = direction;
      }
      else if (direction === 1) {
        header.classList.remove('hide');
        prevDirection = direction;
      }
    };

    window.addEventListener('scroll', checkScroll);

  })();
});